import React from 'react'
import PropTypes from 'prop-types'
import classes from './LeftSide.module.css'

function LeftSide() {
    return (
        <div className={classes.Container} >
            
        </div>
    )
}



export default LeftSide;

