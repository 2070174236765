import React from 'react'

import classes from './RightSide.module.css'

function RightSide() {
    return (
        <div className={classes.Container} >
            
        </div>
    )
}



export default RightSide

